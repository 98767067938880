<template>
  <div>
    <!-- 
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-body p-3">
            <b-button variant="danger">Back</b-button>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-xxl-8">
        <div class="card card-custom gutter-b example example-compact" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t('News.news_detail') }} (Thai)</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" description="" :label="$t('News.title')" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="post.title_th"
                trim
                placeholder="untitle"
                :state="!(post.title_th == '')"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="fieldset-1" description="" :label="$t('News.body')" label-for="input-1">
              <ckeditor :editor="editor" v-model="post.body_th" :config="editorConfig"></ckeditor>
            </b-form-group>
          </div>
        </div>

        <div class="card card-custom gutter-b example example-compact" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t('News.news_detail') }} (English)</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" description="" :label="$t('News.title')" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="post.title_en"
                trim
                placeholder="untitle"
                :state="!(post.title_en == '')"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="fieldset-1" description="" :label="$t('News.body')" label-for="input-1">
              <ckeditor :editor="editor" v-model="post.body_en" :config="editorConfig"></ckeditor>
            </b-form-group>
          </div>
        </div>

        <div class="card card-custom gutter-b example example-compact" v-if="isReady && !!post.attachment">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t('News.images') }}</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-container fluid class="p-4">
              <b-row class="mb-5" v-for="i in Math.ceil(post.attachment.length / 4)" :key="i">
                <b-col v-for="item in post.attachment.slice((i - 1) * 4, i * 4)" :key="item.id">
                  <b-img
                    thumbnail
                    fluid
                    :src="item.path"
                    v-clipboard:copy="item.path"
                    @click="copyImageURL('attach_' + item.id)"
                    :id="'attach_' + item.id"
                  >
                  </b-img>
                  <b-tooltip :show="false" :ref="'attach_' + item.id" :target="'attach_' + item.id" variant="primary"
                    >Click to Copy url</b-tooltip
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <b-overlay :show="isSaving" no-wrap> </b-overlay>
      </div>

      <div class="col-xxl-4">
        <div class="card card-custom gutter-b" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t('News.publish') }}</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" label="สถานะ  " label-for="input-1">
              <b-form-select v-model="post.status" :options="status_list"></b-form-select>
            </b-form-group>
            <b-button variant="primary" class="mb-5" block @click="savePost" v-if="post.status == 0">Save</b-button>
            <b-button variant="success" class="mb-5" block @click="savePost" v-else>Publish</b-button>
          </div>
          <!--end::Body-->
          <b-overlay :show="isSaving" no-wrap> </b-overlay>
        </div>

        <div class="card card-custom gutter-b" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t('News.banner') }}</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" description="" label="" label-for="input-1" class="pt-3">
              <div id="preview">
                <b-img v-if="hasImage" :src="imageSrc" class="mb-3" fluid block rounded> </b-img>
                <b-img v-if="post.image_path && !hasImage" :src="post.image_path" class="mb-3" fluid block rounded>
                </b-img>
              </div>
              <b-button v-if="hasImage" variant="danger" class="btn-sm mb-5" @click="clearImage" block>Delete</b-button>
              <b-form-file
                accept="image/jpeg, image/png"
                v-model="pictureFile"
                placeholder="กรุณาเลือกไฟล์"
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </div>
          <!--end::Body-->
          <b-overlay :show="isSaving" no-wrap> </b-overlay>
        </div>

        <div class="card card-custom gutter-b" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t('News.category') }}</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" label="หมวดหมู่" label-for="input-1">
              <b-form-select
                v-model="post.cat_id"
                :options="category_list"
                :state="!(post.cat_id == 0)"
              ></b-form-select>
            </b-form-group>
          </div>
          <!--end::Body-->
          <b-overlay :show="isSaving" no-wrap> </b-overlay>
        </div>

        <div class="card card-custom gutter-b" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t('News.attachment') }}</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" description="" label="" label-for="input-1">
              <b-form-file
                accept="image/jpeg, image/png"
                v-model="attachFiles"
                placeholder="กรุณาเลือกไฟล์"
                drop-placeholder="Drop file here..."
                :file-name-formatter="formatNames"
                multiple
                :show="!hasFiles"
              >
              </b-form-file>
              <b-button v-if="hasFiles" variant="danger" class="btn-sm mt-5" @click="clearAttachFiles" block
                >Clear</b-button
              >
              <div class="clearfix text-center pt-3" v-if="hasFiles">
                <span class="center"> OR </span>
                <b-button variant="primary" class="btn-sm mt-3" @click="uploadAttachFiles" block
                  >Upload {{ attachFiles.length }} file(s)</b-button
                >
              </div>
            </b-form-group>

            <b-table small responsive="sm" :items="post.attachment" :fields="['filename']" style="overflow: hidden">
              <template #cell()="data">
                {{ data.value | str_limit(30) }}
                <b-button
                  variant="danger"
                  class="btn-sm pt-0 pb-0 pr-2 pl-2 float-right"
                  @click="deleteAttachment(data.item.id)"
                  >x</b-button
                >
              </template>
            </b-table>
          </div>
          <!--end::Body-->
          <b-overlay :show="isSaving || isUploading" no-wrap> </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {SAVE_POST, QUERY, GET_CAT, UPLOAD_FILE, UPLOAD_ATTACH, DELETE_ATTACH} from '../services.js';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';

import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';

class InsertImage extends Plugin {
  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add('insertImage', (locale) => {
      const view = new ButtonView(locale);

      view.set({
        label: 'Insert image',
        icon: imageIcon,
        tooltip: true,
      });

      // Callback executed once the image is clicked.
      view.on('execute', () => {
        const imageUrl = prompt('Image URL');

        editor.model.change((writer) => {
          const imageElement = writer.createElement('imageBlock', {
            src: imageUrl,
          });

          // Insert the image in the current selection location.
          editor.model.insertContent(imageElement, editor.model.document.selection);
        });
      });

      return view;
    });
  }
}

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: 'List',
  components: {},
  data() {
    return {
      isReady: false,
      isSaving: false,
      isUploading: false,
      mode: null,
      post: {
        id: 0,
        title_th: '',
        body_th: '',
        title_en: '',
        body_en: '',
        cat_id: 0,
        image_path: '',
        image_attachment: [],
        status: 0,
      },
      pictureFile: null,
      attachFiles: [],
      imageSrc: null,
      category_list: [{value: 0, text: 'Please select an option'}],
      status_list: [
        {
          value: 0,
          text: 'Draft',
        },
        {
          value: 1,
          text: 'Published',
        },
      ],
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          Essentials,
          Autoformat,
          Bold,
          Italic,
          BlockQuote,
          Heading,
          Link,
          List,
          Paragraph,
          Alignment,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageResize,
          LinkImage,
          InsertImage,
        ],

        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            'bold',
            'italic',
            'insertImage',
            'link',
            'bulletedList',
            'numberedList',
            'uploadImage',
            'blockQuote',
            'undo',
            'redo',
            'resizeImage',
          ],
        },

        image: {
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null,
              icon: 'original',
            },
            {
              name: 'resizeImage:50',
              value: '50',
              icon: 'medium',
            },
            {
              name: 'resizeImage:75',
              value: '75',
              icon: 'large',
            },
          ],
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            'resizeImage:50',
            'resizeImage:75',
            'resizeImage:original',
          ],
        },
      },
    };
  },
  mounted() {
    //get category
    this.getData();

    this.$store.dispatch(SET_BREADCRUMB, [{title: 'News', route: '.'}, {title: 'Edit'}]);
  },
  methods: {
    getData() {
      this.$store
        .dispatch(GET_CAT)
        // go to which page after successfully login
        .then((res) => {
          res.data.forEach((cat) => {
            this.category_list.push({value: cat.id, text: cat.name});
          });
          // this.category_list = res.data;
        })
        .catch((error) => {
          // console.log(error);
        });
      if (this.$route.name == 'news.edit') {
        this.mode = 'edit';
        this.id = this.$route.params.id;
        this.$store
          .dispatch(QUERY, {id: this.id})
          // go to which page after successfully login
          .then((res) => {
            this.post = res.data[0];
            this.isReady = true;
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        this.isReady = true;
        this.mode = 'add';
      }
    },
    savePost(mode) {
      return new Promise((resolve, reject) => {
        if (this.post.cat_id <= 0 || this.post.title_th == '' || this.post.title_en == '') {
          this.notify('danger', 'Error!', 'Please complete the form');
          reject('Please complete the form');
        } else {
          this._savePost(mode);
          resolve();
        }
      });
    },
    _savePost(mode) {
      this.isSaving = true;
      this.$store
        .dispatch(SAVE_POST, {news: this.post})
        // go to which page after successfully login
        .then((res) => {
          if (res.status) {
            this.post = res.data[0];

            //upload banner
            if (this.pictureFile) {
              let formData = new FormData();
              formData.append('file', this.pictureFile);
              this.pictureFile = null;
              this.$store
                .dispatch(UPLOAD_FILE, formData)
                // go to which page after successfully login
                .then((res) => {
                  if (res.status) {
                    this.post.image_path = res.data.image_path;
                  }
                  // console.log('Upload Banner');
                  this.isSaving = false;
                  this.pictureFile = null;
                  //Update image path
                  this.savePost('update_image');
                })
                .catch((error) => {
                  this.isSaving = false;
                  // console.log(error);
                });
            }

            //Upload Attachment
            this.uploadAttachFiles();

            // this.uploadFile();
            if (this.$route.name == 'news.add') {
              this.$router.push({
                name: 'news.edit',
                params: {id: this.post.id},
              });
            }
          }
          // console.log('Save post');
          this.isSaving = false;
          if (mode != 'update_image') {
            this.notify('success', 'Successfully!', 'Post Saved.');
            if (this.post.status == 1) {
              this.$router.push({
                name: 'news.list',
              });
            }
          }
        })
        .catch((error) => {
          this.isSaving = false;
          // console.log(error);
        });
    },
    uploadAttachFiles() {
      if (this.post.id) {
        //Upload Attachment
        if (this.attachFiles) {
          this.isUploading = true;
          const attachNum = this.attachFiles.length;
          let i = 0;
          this.attachFiles.forEach((item, index) => {
            let formData = new FormData();
            formData.append('file', item);
            // console.log(item.name);
            formData.append('id', this.post.id);
            formData.append('table', 'news');
            this.$store
              .dispatch(UPLOAD_ATTACH, formData)
              .then((res) => {
                i++;
                if (res.status) {
                  this.notify('success', 'Successfully!', 'Upload [' + res.data.filename + '] successfully!');
                } else {
                  this.notify('success', 'Successfully!', 'Upload [' + item.name + '] successfully!');
                }
                if (i == attachNum) {
                  this.getData();
                }
              })
              .catch((err) => {});
          });
          this.attachFiles = [];
          setTimeout(() => {
            this.isUploading = false;
          }, 1000);
        }
      } else {
        this.savePost();
      }
    },
    deleteAttachment(id) {
      this.isUploading = true;
      this.$store
        .dispatch(DELETE_ATTACH, {id: id})
        .then((res) => {
          this.getData();
          this.isUploading = false;
        })
        .catch((err) => {
          this.isUploading = false;
        });
    },
    copyImageURL(id) {},
    formatNames(files) {
      return files.length === 1 ? files[0].name : `${files.length} files selected`;
    },
    clearImage() {
      this.pictureFile = null;
    },
    clearAttachFiles() {
      this.attachFiles = [];
    },
    notify(variant = null, title, text) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  computed: {
    hasImage() {
      return !!this.pictureFile;
    },
    hasFiles() {
      return !!this.attachFiles.length;
    },
  },
  watch: {
    pictureFile: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
